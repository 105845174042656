<template>
  <div>
    <sHeader></sHeader>
    <el-tabs type="border-card" style="padding: 20px">
      <qa></qa>
    </el-tabs>
  </div>
</template>

<script>
import SHeader from '../components/signInSignUp/SHeader';
import Qa from '../components/qa/Qa';
export default {
  components: {
    Qa,
    SHeader,
  },
  name: 'about-qa',
};
</script>

<style lang="scss" scoped>
</style>
