<template>
  <div class="login-header">
    <svg-icon icon-class="logo" class="cloudlogo"></svg-icon>
  </div>
</template>

<script>
export default {
  name: 's-header',
};
</script>

<style lang="scss" scoped>
.login-header {
  background-color: #1c63aa;
  height: 55px;
  padding: 5px 10px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.164);
  text-align: left;
}
@media (max-width: 764px) {
  .login-header {
    text-align: center;
  }
}
.cloudlogo {
  width: 130px;
  height: 55px;
}
</style>
