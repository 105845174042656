/**
 * questionnaireSet：問卷設定
 * screenAudience：篩選受眾
 * pushSet：推播設定
 * costCalculation：費用計算
 * questionnaireVerify：問卷審核
 */

//問卷設定
const questionnaireSet = {
  qas: [
    {
      id: 'tab1-1',
      title: '問卷版型設定中「一頁多題組」的意思是什麼？',
      answer: [
        '一頁多題組意思即填答問卷者在APP看到的填答畫面，一個填答頁面包含5個題組(一個題組最多5題)，若其中有包含單一題目沒有納入題組，也算入一個題組的計算中。',
      ],
    },
    {
      id: 'tab1-2',
      title: '如何在回答選項中設定「其他」填答選項？',
      answer: [
        '可在有「其他」填答選項的題目後再設定一題簡答題，並在題目都新增完畢後，設定「跳題」至簡答題。',
      ],
    },
    {
      id: 'tab1-3',
      title: '如何查看問卷被投放出去呈現在APP的畫面？',
      answer: [
        '可在問卷列表「未開始」、「刊登中」下拉功能列表中的「預覽」檢視問卷在APP呈現的畫面喔！',
      ],
    },
    {
      id: 'tab1-4',
      title: '如何設定問卷群組在同一個回答情境架構？',
      answer: [
        '在新增題組後可點選「編輯」選擇題組顏色，讓問卷填答者可在同一個顏色架構情境下回答問題。',
      ],
    },
    {
      id: 'tab1-5',
      title: '跳題可以跳回前面的題目嗎？',
      answer: [
        '為了確保填答真實性，填答者不可回溯上一頁修改答案，所以跳題設定僅能跳到該題之後的題目。',
      ],
    },
    {
      id: 'tab1-6',
      title: '如何拖曳題目/題組？',
      answer: [
        '按住題目/題組旁的「<i class="el-icon-sort"></i>」即可隨意拖曳進出題組，請務必留意拖曳後，需重新檢查跳題設定。',
      ],
      html: true,
    },
    {
      id: 'tab1-7',
      title: '為什麼拖曳題目/題組後，預覽問卷沒有即時更新？',
      answer: ['只要有拖曳設定後，務必要按「儲存排序」並檢查跳題設定喔！'],
    },
    {
      id: 'tab1-8',
      title: '生日題填答格式為何？',
      answer: ['西元年/月/日。'],
    },
    {
      id: 'tab1-9',
      title: 'email題會檢查格式是否正確嗎？',
      answer: ['會於填答者未輸入正確格式(例如沒有輸入@)時會提醒填答內容有誤。'],
    },
    {
      id: 'tab1-10',
      title: '簡答題會防堵填答問卷者亂填的機制嗎？',
      answer: ['若填答者只有輸入空格或亂數符號時會提醒填答內容有誤。'],
    },
    {
      id: 'tab1-11',
      title: '問卷可刊登效期為多久？',
      answer: [
        '開始日期當天10:00刊登問卷(單數月25號逢發票開獎，為避免財政部系統異常造成APP使用體驗不穩定，故不開放當天刊登問卷)。',
        '結束日期當天10:00下架問卷。',
        '總時間範圍不得超過90天(包含延長問卷時間)。',
      ],
    },
    {
      id: 'tab1-12',
      title: '問卷刊登中還可以編輯嗎？',
      answer: [
        '問卷一旦送出後即無法編輯喔！請在送出申請審核前仔細檢查題目設定是否正確。',
      ],
    },
  ],
};

//篩選受眾
const screenAudience = {
  qas: [
    {
      id: 'tab2-1',
      title: '如何從篩選受眾中選擇推播人數？',
      answer: [
        '篩選出來的總受眾人數中，我們會隨機挑選出符合你設定的推播數量人數，協助你的問卷發揮最大的曝光度。',
      ],
    },
    {
      id: 'tab2-2',
      title:
        '篩選受眾條件中的「年齡」、「性別」中又有在分類自填與預測是什麼意思？',
      answer: ['自填為受測者自願揭露訊息、預測為根據受測者消費足跡推估。'],
    },
    {
      id: 'tab2-3',
      title: '篩選受眾條件中的「個性化標籤」條件標準為何？',
      answer: [
        '「環保意識」：消費足跡包含環保、有機、無毒、植萃、自備、自帶及有機品牌等。',
        '「健康意識」：消費足跡包含保健食品、運動器材/用具/費用。',
        '「新奇時尚」：消費足跡每季都會購買快時尚品牌衣物。',
        '「休閒享樂」：消費足跡包含休閒、電玩遊戲與周邊。',
        '「精打細算」：消費足跡包含折扣商品、友善時光商品、過季出清品。',
      ],
    },
    {
      id: 'tab2-4',
      title: '篩選受眾條件中的「消費通路習慣」、「品牌習慣」條件標準為何？',
      answer: [
        '「消費通路習慣」為該通路消費過2次以上的受眾、「品牌習慣」為該品牌消費過2次以上的受眾。',
      ],
    },
    {
      id: 'tab2-5',
      title: '條件列表的計算邏輯為何？',
      answer: [
        '相同類別中的條件會優先完成篩選，此類別之條件結果才會與不同類別之條件結果再次篩選。',
        '篩選之優先順序為先計算“且”關係的條件，並依序由上往下計算符合條件受眾人數。',
        '若有加入排除條件，則為正向條件先算出結果，再扣除排除條件算出的結果。兩者獨立運算後再相減。',
      ],
    },
  ],
};

//推播設定
const pushSet = {
  qas: [
    {
      id: 'tab3-1',
      title: '什麼是推播？',
      answer: ['推播即為APP在手機上的通知訊息。'],
    },
    {
      id: 'tab3-2',
      title: '首次推播的人數可以自己選擇人數嗎？',
      answer: [
        '為了讓問卷回收更有效率，系統已根據過去發送推播問卷經驗，預設最低5%填答率換算建議首次推播人數，為投放問卷的基本推播人數，若欲加速問卷回收速度，可於問卷刊登中設定再次推播。',
      ],
    },
    {
      id: 'tab3-3',
      title: '首次推播的推播發送時間為何？',
      answer: ['於問卷開始日當天10:00開始推播。'],
    },
    {
      id: 'tab3-4',
      title: '再次推播的人數可以自己選擇人數嗎？',
      answer: [
        '可根據選擇推播受眾人數(推播問卷受眾人數 - 目前已回收問卷人數)選擇欲再次推播人數，系統會優先推播沒有收過推播的受眾。',
      ],
    },
    {
      id: 'tab3-5',
      title: '再次推播的推播發送時間為何？',
      answer: [
        '再次推播申請時間若超過當日上午10:00，於隔天上午10:00發送推播。',
        '問卷結束日前一天無法申請再次推播。',
      ],
    },
  ],
};

//費用計算
const costCalculation = {
  qas: [
    {
      id: 'tab4-1',
      title: 'pro進階篩選收費標準為何？',
      answer: [
        '只有選擇進階篩選欄位(個人特質、消費行為)，每新增一個條件，將以下列公式進行收費： pro進階篩選(個) x pro進階服務費(5點/人) x 回收份數上限，一個條件不限制使用正向或排除條件，亦不限制單一條件再加入同條件聯集。',
      ],
    },
    {
      id: 'tab4-2',
      title: '若使用預算上限計算問卷費用，是會預扣預算上限還是預估費用？',
      answer: [
        '預算上限只是回推預估費用的標準，送出申請時還是會依照預估費用進行預扣。',
      ],
    },
    {
      id: 'tab4-3',
      title: '何時會預扣問卷預估費用？',
      answer: [
        '問卷都設定完畢並送出申請時，當下即會預扣問卷預估費用並進行問卷審核。',
      ],
    },
    {
      id: 'tab4-4',
      title: '問卷結束後，預扣費用何時會把沒用到的點數返還？',
      answer: [
        '系統將預扣預估費用點數，待問卷結束後將自動計算是否有需返還點數，並於3個工作天內完成點數返還作業，系統會透過註冊申請的email帳號通知，首次推播為一次性費用，問卷一旦進入刊登中即開始推播，不列入扣點返還範圍。',
      ],
    },
    {
      id: 'tab4-5',
      title: '如何查看我的點數？',
      answer: ['可於問卷列表右上方箭頭點選「我的帳戶」查看點數資訊。'],
      img: true,
      qaImg: require('@/assets/image/qa.png'),
    },
    {
      id: 'tab4-6',
      title: '若填答者問卷沒有回答完成也會給予填答回饋點數嗎？',
      answer: ['若填答者沒有完成填答問卷，則不給予填答回饋點數。'],
    },
  ],
};

//問卷審核
const questionnaireVerify = {
  qas: [
    {
      id: 'tab5-1',
      title: '為什麼問卷需要審核？',
      answer: [
        '由於Target-Q為問卷平台的角色，有義務保障填答問卷者和問卷主雙方的權益，因此需要進行問卷審核。',
      ],
    },
    {
      id: 'tab5-2',
      title: '審核時間多久？這段時間還可以編輯問卷嗎？',
      answer: [
        '審核時間為7個工作日，一旦問卷送出申請審核後，即無法再編輯問卷內容。',
      ],
    },
    {
      id: 'tab5-3',
      title: '審核標準為何？',
      directions:
        '若問卷內容有包含以下內容，我們會將未刊登之問卷退回或刊登中問卷進行強制下架：',
      answer: [
        '冒犯、侮辱、誹謗、色情、威脅或淫穢的資料。',
        '非法或意圖助長或進行任何形式非法行為的資料，包含但不限於侵犯智慧財產權、隱私權、Target-Q 或第三方的專屬權利。',
        '與客觀事實不符的虛假消息。',
        '包含您的密碼或故意包含其他使用者密碼或第三方個人資料，或是意圖徵求此類個人資訊的資料。',
        '包含惡意內容，例如惡意軟體：木馬程式、病毒，或是以其他方式干擾其他使用者存取服務的資料。',
        '意圖或故意騷擾或恐嚇其他使用者的資料。',
        '冒充或謊稱您與其他使用者、個人或實體存在關聯，或是以其他方式詐騙、偽造、欺詐或誤導的資料。',
        '使用自動工具以造假方式宣傳內容的資料。',
        '涉及不請自來的群組郵件或其他形式的垃圾郵件 (「垃圾郵件」)、廣告郵件、連鎖信件或類似信件（包括透過 Target-Q 的收件匣）的資料。',
        '涉及未經 Target-Q 明確授權的商業或銷售活動（例如：廣告、促銷、競賽、抽獎或直銷）的資料。',
      ],
    },
    {
      id: 'tab5-4',
      title: '如何知道我的問卷已經審核通過了？',
      answer: ['只要問卷狀態有異動，系統會透過註冊申請的email帳號通知喔！'],
    },
  ],
};

const qa = {
  data() {
    return {};
  },
  computed: {
    getQas() {
      switch (this.currentState) {
        case '1':
          return questionnaireSet.qas;
        case '2':
          return screenAudience.qas;
        case '3':
          return pushSet.qas;
        case '4':
          return costCalculation.qas;
        case '5':
          return questionnaireVerify.qas;
      }
    },
  },
};

export default qa;
