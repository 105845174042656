<template>
  <div>
    <!-- 標籤 -->
    <el-tabs
      type="border-card"
      v-model="currentState"
      @tab-click="handleTabClick"
    >
      <div class="qa-btn">
        <el-button type="primary" @click="flowDownload">下載操作手冊</el-button>
      </div>

      <el-tab-pane
        v-for="qaType in tabs"
        :key="qaType.qas"
        :label="qaType.label"
        :lazy="true"
        :name="qaType.status"
      >
        <!-- 問答 -->
        <el-collapse v-model="activeNames" :current-state="currentState">
          <el-collapse-item
            v-for="(qa, index) in getQas"
            :key="qa.id"
            :prop="qa.id"
            :label="qa.title"
          >
            <template slot="title">
              <div>Q{{ index + 1 }} : {{ qa.title }}</div>
            </template>
            <!-- tab5-3 -->
            <div>{{ qa.directions }}</div>
            <div style="display: flex">
              <ol
                style="margin: 0"
                :length="qa.answer.length"
                :class="qa.answer.length === 1 ? 'remove-bit' : 'sequence'"
              >
                <li slot=" a " v-for="(a, index) in qa.answer" :key="index">
                  <template>
                    <div v-if="qa.html" v-html="a"></div>
                    <div v-else>{{ a }}</div>
                    <!-- tab4-5 -->
                    <div v-if="qa.img">
                      <img class="qa-img" :src="qa.qaImg" alt="" />
                    </div>
                  </template>
                </li>
              </ol>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import qa from '@/mixin/qa';
export default {
  name: 'qa',

  mixins: [qa],
  data() {
    return {
      activeNames: [''],
      currentState: '1',
      tabs: [
        {
          label: '問卷設定',
          status: '1',
        },
        {
          label: '篩選受眾',
          status: '2',
        },
        {
          label: '推播設定',
          status: '3',
        },
        {
          label: '費用計算',
          status: '4',
        },
        {
          label: '問卷審核',
          status: '5',
        },
      ],
    };
  },
  watch: {
    $route() {
      this.currentState = this.$route.query.status || '1';
    },
    currentState() {
      if (this.$router.path === 'qa') {
        this.$router.path
          .push({
            path: 'qa',
            query: { status: this.currentState },
          })
          .catch(() => {});
      } else if (this.$router.path === 'target-qa') {
        this.$router.path
          .push({
            path: 'target-qa',
            query: { status: this.currentState },
          })
          .catch(() => {});
      }
    },
  },
  created() {
    console.log(process.env.BASE_URL);
    if (this.$route.query.status) {
      this.currentState = this.$route.query.status;
    }
  },
  methods: {
    handleTabClick() {
      this.isGetData = true;
      const query = { status: this.currentState };
      this.$router.push({ query }).catch(() => {});
    },
    flowDownload() {
      let a = document.createElement('a'); //創建一个<a></a>標籤
      a.href = 'static/TargetQ-operation-manual-v2.pdf'; // 给a標籤的href屬性加上路徑，注意，這裡是絕對路徑，不用加 點.
      a.download = '操作手冊.pdf'; //設置下載文件名，這裡加上.pdf指定文件類型
      a.style.display = 'none'; // 障眼法藏起來a標籤
      document.body.appendChild(a); // 將a標籤追加到文檔物件中
      a.click(); // 模擬點擊了a標籤，會觸發a標籤的href的讀取，瀏覽器就會自動下載了
      a.remove(); // 一次性的，用完就删除a標籤
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-collapse-item__header {
  height: 0;
  line-height: 25px;
  padding: 35px 0;
}
/deep/.remove-bit {
  list-style-type: none;
  padding-left: 5px;
}
/deep/.sequence {
  list-style-type: decimal;
  padding-left: 20px;
}
/deep/.qa-img {
  width: 95%;
}
/deep/.qa-btn {
  padding: 0px 5px;
  display: flex;
  justify-content: flex-end;
}

/deep/.el-button {
  font-weight: 300;
}
/deep/.el-collapse {
  border-top: none;
}
/deep/.el-link.el-link--default:after {
  border-color: transparent;
}
/deep/.el-collapse-item__content {
  font-size: 14px;
  padding-left: 25px;
}
/deep/.el-icon-arrow-right:before {
  content: '\e6e1';
}
/deep/.el-collapse-item__arrow.is-active {
  transform: rotate(180deg);
}
/deep/.el-collapse-item__header {
  font-size: 14px;
}
/deep/.el-tabs--border-card > .el-tabs__content {
  padding: 20px;
}
</style>
